(function($) {

    function new_map( $el ) {
        var $markers = $el.find('.marker');

        var args = {
            zoom				: 18,
            center				: new google.maps.LatLng(0, 0),
            mapTypeId			: google.maps.MapTypeId.ROADMAP,
            scrollwheel			: false,
            navigationControl	: true,
            mapTypeControl		: true,
            scaleControl		: true,
            draggable			: true,
            disableDefaultUI	: true,
            styles 				: [{"featureType":"landscape.man_made","elementType":"geometry","stylers":[{"visibility":"simplified"},{"hue":"#ff0000"},{"saturation":"-100"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road.highway.controlled_access","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"road.local","elementType":"all","stylers":[{"visibility":"on"},{"saturation":"-35"},{"weight":"1.55"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"visibility":"off"},{"hue":"#ff0000"},{"saturation":"-100"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"hue":"#ff0000"},{"saturation":"-100"},{"weight":"0.50"}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"visibility":"off"}]},{"featureType":"transit.station","elementType":"all","stylers":[{"visibility":"on"},{"hue":"#ff0000"},{"saturation":"-100"}]},{"featureType":"transit.station","elementType":"geometry","stylers":[{"visibility":"off"}]},{"featureType":"transit.station","elementType":"geometry.fill","stylers":[{"visibility":"on"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"on"},{"hue":"#ff0000"},{"saturation":"-100"}]}]
        };

        var map = new google.maps.Map( $el[0], args);
        map.markers = [];

        $markers.each(function(){
            add_marker( $(this), map );
        });


        center_map( map );

        return map;

    }

    function add_marker( $marker, map ) {

        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        var icon_size = 122;

        if(jQuery('html').hasClass('mobile')){
            icon_size = 75;
        }

        var icon = {
            url: "//clients.thekitchen.agency/hemmi/hemmi_map_icon.png",
            scaledSize: new google.maps.Size(66, 106),
        };

        var marker = new google.maps.Marker({
            position	: latlng,
            //icon        : icon,
            animation   : google.maps.Animation.DROP,
            size        : new google.maps.Size(66,106),
            map			: map
        });

        map.markers.push( marker );

        if( $marker.html() ){

            var infowindow = new google.maps.InfoWindow({
                content		: $marker.html()
            });

            google.maps.event.addListener(marker, 'click', function() {
                infowindow.open( map, marker );
            });
        }
    }

    function center_map( map ) {
        var bounds = new google.maps.LatLngBounds();
        var latlng;

        $.each( map.markers, function( i, marker ){
            latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
        });

        if( map.markers.length == 1 ){
            map.setCenter( bounds.getCenter() );
            map.setZoom( 14 );
            map.panBy(0,0);
        } else {
            // fit to bounds
            map.fitBounds( bounds );
        }
    }

    var map = null;

    $(document).ready(function(){
        $('.acf-map').each(function(){
            // create map
            map = new_map( $(this) );
        });
    });

})(jQuery);




