/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                console.log("here");
                // JavaScript to be fired on all pages, after page specific JS is fired
                $nav = $('.cd-stretchy-nav');

                if ($nav.length > 0) {
                    var stretchyNavs = $nav;

                    console.log("there");

                    stretchyNavs.each(function () {
                        var stretchyNav = $(this),
                            stretchyNavTrigger = stretchyNav.find('.cd-nav-trigger');

                        stretchyNavTrigger.on('click', function (event) {
                            console.log("click");
                            event.preventDefault();
                            stretchyNav.toggleClass('nav-is-visible');
                            jQuery('.nav-container').toggleClass('mobileheight');

                            if ($(window).width() < 768) {
                                jQuery('.nav-container').addClass('active');
                            }

                        });
                    });

                    $(document).on('click', function (event) {
                        if (!$(event.target).is('.cd-nav-trigger') && !$(event.target).is('.cd-nav-trigger span')) {
                            stretchyNavs.removeClass('nav-is-visible');
                            jQuery('.nav-container').removeClass('mobileheight');

                        }
                    });
                }

                $(window).scroll(function () {
                    var y = jQuery(document).scrollTop();
                    if (y > jQuery('.full-height-header').height() - 80) {
                        jQuery('.nav-container').addClass('active');
                    } else {
                        jQuery('.nav-container').removeClass('active');
                    }
                });

                $('a[href*="#"]')
                    .not('[href="#"]')
                    .not('[href="#0"]')
                    .click(function (event) {
                        // On-page links
                        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                            var target = $(this.hash);
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                            if (target.length) {
                                event.preventDefault();
                                $('html, body').animate({
                                    scrollTop: target.offset().top - 180
                                }, 1000);
                            }
                        }
                    });

                //Tenant Resources (accordion)
                $("dt").click(function (e) {
                    e.preventDefault();
                    if ($(this).find('a').hasClass('open')) {
                        $('dd').slideUp("fast");
                        $('dt > a').removeClass('open').addClass("closed");
                        $('dt span').removeClass('active');
                    } else {
                        $('dd').slideUp("fast");
                        $('dt > a').removeClass('open').addClass("closed");
                        $(this).next("dd").slideDown("fast");
                        $(this).children("a").removeClass('closed').addClass("open");
                        $(this).find('span').addClass('active');
                    }
                });



                var hamby = jQuery('.hamburger');

                hamby.on('click', function (e) {
                    jQuery(this).toggleClass('is-active');
                });



                jQuery('.scroller-icon').on('click', function (e) {
                    $('html, body').animate({
                        scrollTop: $("#management").offset().top - 160
                    }, 1000);
                });
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // Rotator
                var slideIndex = 0;


                function showSlides() {
                    var i;
                    var slides = document.getElementsByClassName("slide");
                    for (i = 0; i < slides.length; i++) {
                        slides[i].classList.remove('active');
                    }
                    slideIndex++;
                    if (slideIndex > slides.length) {
                        slideIndex = 1;
                    }
                    slides[slideIndex - 1].classList.add('active');
                    setTimeout(showSlides, 6000);
                }

                showSlides();
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
